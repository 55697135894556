export class Endpoint {
    public static LEAD_MICROSERVICE: string = "https://lead-management-service-rlv2mx3dla-uc.a.run.app";
    public static ADMIN_CONFIG_MICROSERVICE: string = "https://admin-config-nest-rlv2mx3dla-uc.a.run.app";
    public static CUSTOMER_MICROSERVICE: string = "https://customer-management-service-rlv2mx3dla-uc.a.run.app";
    public static DOCUMENT_MICROSERVICE: string = "https://document-management-service-rlv2mx3dla-uc.a.run.app";
    public static BENEFICIARY_MICROSERVICE: string = "https://beneficiary-management-service-rlv2mx3dla-uc.a.run.app";    
    public static USER_MICROSERVICE: string = "https://user-management-service-rlv2mx3dla-uc.a.run.app";
    public static CASE_MICROSERVICE: string = "https://case-management-service-rlv2mx3dla-uc.a.run.app";
    public static ATTORNEY_MICROSERVICE: string = "https://attorney-service-rlv2mx3dla-uc.a.run.app";
    public static CLOUD_FUNCTIONS: string = "https://us-central1-intense-baton-327115.cloudfunctions.net"
} 
