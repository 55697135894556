import { ThrowStmt } from '@angular/compiler';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RoleModel } from 'app/main/apps/admin-management/model/role.model';
import { BeneficiaryManagementService } from 'app/main/apps/beneficiary-managment/beneficiary-management.service';
import { CustomerManagementService } from 'app/main/apps/customer-management/customer-management.service';
import { AdminConfigService } from 'app/main/apps/services/admin-config.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UserRoleModel } from '../../model/user-role.model';
import { UserManagementService } from '../../user-management.service';
import { EmailService } from '../../../email/services/email.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-add-new-user-sidebar',
  templateUrl: './add-new-user-sidebar.component.html',
  styleUrls: ['./add-new-user-sidebar.component.scss']
})
export class AddNewUserSidebarComponent implements OnInit {

  @Output() closeSideBar = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;
  @Input() roles: RoleModel[];
  public firstName;
  public lastName;
  public userEmail;
  public userPhonenumber;
  public selectedRoles;
  public selectedCustomer;
  public active: boolean = true;
  public errorMessage: string = '';
  public customers = [];
  public customer : any;
  public empBeniFlag :  boolean = false;
  public beneficiries = [];
  public selectedbeneficiary;
  public isVisible : boolean = true;
  lawfirmName: any;
  subject: any;
  content; any;
  public signature: any;
  public regards: any;
  @ViewChild('newRoleForm') form: FormGroup;
  isDisabled:boolean=true;
  @ViewChild('userSaveAlert') userSaveAlert: any;
  constructor(private authService: AuthenticationService, private _emailService: EmailService, private _userManagementService: UserManagementService, private _adminManagementService: AdminConfigService, private cdr: ChangeDetectorRef,
    private _customerManagementService: CustomerManagementService, private _beneficiaryManagementService: BeneficiaryManagementService) { }

  async ngOnInit(): Promise<void> {
    this.active = true;
    [this.customers, this.beneficiries] = await Promise.all([  this._customerManagementService.getCustomerBasicDetails(), this._beneficiaryManagementService.getBasicBeneficiaries()]);
    this.beneficiries = this.beneficiries.filter( b => b.beneficiaryType == 'Employee' || b.beneficiaryType == 'Individual')?.map(ele => {
      return { code: ele,
        description: ele.beneficiaryFirstName + ' ' + ele.beneficiaryLastName 
      };  
    });   
    // this.selectedRoles = this.roles;           
  }

  toggleSidebar(name): void {
    //console.log((this.form.value.roles as RoleModel[])?.map(r => r.role_id));
    this.closeSideBar.emit(name);
  }

  async submit(form) {
   // var arr:string[]=[];
    try {
      this.errorMessage = '';
      if (form.valid || (this.selectedRoles?.role_name=='Beneficiary' || this.selectedRoles?.role_name=='Employer')) {
       // arr.push(this.form.value.roles.role_id);
        this.blockUI.start();
        const request = {
          emailAddress: this.userEmail,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: "+"+this.userPhonenumber,
          //role: (this.form.value.roles as RoleModel[])?.map(r => r.role_name)[0],
          role: (this.form.value.roles)?.role_name,
          customerId: this.customer?.customer_id,
          status : true
        };
        const response = await this._userManagementService.createUser(request);
        if (response && response.userId) {
          await this.updateNewUserRoles(response);
          await this.resendEmail(this.firstName, this.userEmail);
          this.userSaveAlert.fire();
          this.form.reset();
          this.closeSideBar.emit('add-new-user-sidebar');
        } else {
          this.errorMessage = response.message;
        }
      }
    } catch (error) {
      this.errorMessage = error;
    } finally {
      this.blockUI.stop();
    }
  }

  private async updateNewUserRoles(response: any) {
    let arr:string[]=[];
    arr.push(this.form.value.roles?.role_id);
    const request = <UserRoleModel>{
      uid: response.userId,
      //roles: (this.form.value.roles as RoleModel[])?.map(r => r.role_id),
      roles: arr,
      disabled: false,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName
    };
    await this._userManagementService.updateUserRoles(request);
  }

  changeDropdown() {
    if (this.selectedRoles?.role_name!='Beneficiary' && this.selectedRoles?.role_name!='Employer') {
      this.isDisabled = true;
      this.selectedCustomer='';
    } else {
      if(this.selectedRoles?.role_name=='Employer') {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
      this.isDisabled = false;
    }
    this.empBeniFlag  = false;
    this.firstName = '';
    this.lastName = '';
    this.userEmail = '';
    this.userPhonenumber = '';   
    this.selectedCustomer = '';
    this.selectedbeneficiary = ''; 
    this.active = true;
  }

  async getCompanyDetails() {
    if(this.selectedCustomer && this.selectedRoles.role_name === 'Employer') {
      // this.isDisabled = true;
      this.isVisible = true;
      this.blockUI.start();
      this.customer = this.selectedCustomer;
      this.firstName = this.customer.customerOwner.ownerFirstName;
      this.lastName = this.customer.customerOwner.ownerLastName;
      this.userEmail = this.customer.customerOwner.ownerEmailId;
      this.userPhonenumber = "1"+this.customer.customerOwner.ownerWorkPhone;
      this.empBeniFlag  = true;    
      this.blockUI.stop();
    }
  }

  async getBeneficiaryDetails() {
    this.selectedCustomer = null;
    this.customer = null;
    if(this.selectedbeneficiary?.code && this.selectedRoles.role_name === 'Beneficiary') {
      //console.log(this.selectedbeneficiary?.code);
      this.blockUI.start();
      if(this.selectedbeneficiary?.code.beneficiaryType) {
        if(this.selectedbeneficiary?.code.beneficiaryType != 'Individual') {
          this.isVisible = true;
          let customer =  this.customers.filter( ele => ele.customer_id == this.selectedbeneficiary?.code.beneficiaryCompanyId);
          this.selectedCustomer = customer[0];
          this.customer = customer[0];
        } else {
          this.isVisible = false;
        }
        this.firstName = this.selectedbeneficiary?.code.beneficiaryFirstName;
        this.lastName = this.selectedbeneficiary?.code.beneficiaryLastName;
        this.userEmail = this.selectedbeneficiary?.code.beneficiaryPrimaryEmailAddress;
        this.userPhonenumber = "1"+this.selectedbeneficiary?.code.beneficiaryCellNumber;
        this.empBeniFlag  = true;  
      }
      this.blockUI.stop();
    }
  }

  async getEmailContent(templateName : string){
    try {
      let response = await this._emailService.getEmailTemplates('verficationMail');
      (await response).forEach( ele => {
        if (ele.template_name == templateName) {
          this.content = ele.template;
          this.subject = ele.subject;
        }
    });
    } catch (e) {
      console.log(e);
    }
  }

  async resendEmail(firstName : string, userEmail : string) {
    this.blockUI.start();
    try {
      var templateName = 'EmailVerficationTemplate';
      var loginLink = 'https://'+window.location.host+'/pages/authentication/login-v2';
      const lawfirmResponse = await this._adminManagementService.getLawfirm();
      this.lawfirmName = lawfirmResponse.law_firm_name;
      var loggedinEmail = this.authService.currentUserValue.email;
      var fromName = this.authService.currentUserValue.firstName +" "+this.authService.currentUserValue.lastName;

      let response = await this._emailService.getEmailSignatures(this.authService.currentUserValue.id);
      let currentSignature = response ? response.find(x => x.defvalue ==true) : [];
      this.signature =  (null != currentSignature && '' != currentSignature && undefined != currentSignature.body) ? currentSignature.body : this.lawfirmName;
      this.regards = (null != currentSignature && '' != currentSignature && undefined != currentSignature.body) ? '' : 'Sincerely,';
    
      this.signature = this.signature.replace(/<p/g, '<p  style="margin:0 !important"');
      await this.getEmailContent(templateName);
      this.subject = this.subject.replace(/{lawfirmName}/g, this.lawfirmName).replace( /&amp;/g, '&');
      this.content = this.content.replace(/{loginLink}/g, loginLink).replace(/{firstName}/g, firstName).replace(/{regards}/g, this.regards).replace(/{lawfirmName}/g, this.signature);
      await this._userManagementService.passwordReset({subject: this.subject, content: this.content, emailAddress: userEmail, loggedinUserMail: loggedinEmail, fromName: fromName,
      url: loginLink}, {});
      let userId =  this.authService.currentUserValue.id;
      await this.updateSentMail(templateName, userId, loggedinEmail, fromName, userEmail);
    } catch (error) {
      throw error;
    } finally {
      this.blockUI.stop();
    }
  }

  setActive() {
    this.active = true;
  }

  async updateSentMail(templateName : string, userId : any, loggedinEmail : string, fromName : string, userEmail: string) {
    try {
      let mailObj = {};
      mailObj['fromEmail'] = 'info@lawtrax.com';
      mailObj['fromName'] = fromName;
      mailObj['replyToEmail'] = loggedinEmail;
      mailObj['toEmail'] = [userEmail];
      mailObj['ccEmail'] = [];
      mailObj['bccEmail'] = [];
      mailObj['body'] = this.content;
      mailObj['subject'] = this.subject;
      mailObj['templateName'] = templateName;
      mailObj['userId'] = userId;
      mailObj['associateId'] = '';
      mailObj['sentDate']= new Date();
      await this._emailService.updateSentMail(mailObj);
    } catch (e) {
      console.log(e.errorMessage);
    }
  }
}