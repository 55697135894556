<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
</div>

<section class="horizontal-wizard">
  <div id="stepper1" class="bs-stepper horizontal-wizard-example">
    <div class="bs-stepper-header">
      <div class="step" data-target="#attorney-users">
        <button class="step-trigger" (click)="tabChange('Admin')">
          <span class="bs-stepper-box">
            <i class="feather icon-user"></i>
          </span>
          <span class="bs-stepper-label">
            <span class="bs-stepper-title">Attorney Users</span>
            <!-- <span class="bs-stepper-subtitle">Add Basic Details</span> -->
          </span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#employers">
        <button class="step-trigger" (click)="tabChange('Employer')">
          <span class="bs-stepper-box">
            <i class="feather icon-user"></i>
          </span>
          <span class="bs-stepper-label">
            <span class="bs-stepper-title">Employers</span>
            <!-- <span class="bs-stepper-subtitle">Add Education & Work Experiene Info</span> -->
          </span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#beneficiaries">
        <button class="step-trigger" (click)="tabChange('Beneficiary')">
          <span class="bs-stepper-box">
            <i class="feather icon-user"></i>
          </span>
          <span class="bs-stepper-label">
            <span class="bs-stepper-title">Beneficiaries</span>
            <!-- <span class="bs-stepper-subtitle">Add Immgration Info</span> -->
          </span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content">
      <!-- Basic Details -->
      <div id="attorney-users" class="content">

        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">Show
                <select class="form-control mx-25" [(ngModel)]="page.limit" (ngModelChange)="loadData()">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              <label class="d-flex align-items-center ml-1 ml-md-0">Search:<input name="searchValue" type="search"
                  class="form-control ml-25 search-box" [(ngModel)]="page.searchValue" (ngModelChange)="clear()"
                  (keyup.enter)="filterUpdate($event)" />
                <button class="reset-icon" type="reset" (click)="reset()"></button></label>
              <button class="btn btn-primary ml-1" rippleEffect (click)="addUser('add')">
                <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                  class="d-none d-sm-inline-block">Create User</span>
              </button>
              <!-- <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('add-new-team-sidebar')">
                          <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                              class="d-none d-sm-inline-block">Add Team</span>
                          </button> -->
            </div>
          </div>
        </div>
        <ngx-datatable [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap" [footerHeight]="30"
          [limit]="page.limit" [externalPaging]="true" [count]="page.count" [offset]="page.offset"
          (page)="datatablePageData($event)" [sorts]="[{prop: 'auditInfo.ChangeTimeStamp', dir: 'desc'}]"
          [columnMode]="ColumnMode.force" [headerHeight]="50" [scrollbarH]="true">
          <ngx-datatable-column [sortable]="false" name="Name" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="cell-line-height">
                  <a href="javascript:;" (click)="editSelectedUser(row,'edit')" class="font-medium-1 d-block"><span
                      class="font-weight-bold">{{ row.firstName }} {{ row.lastName }}</span></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Email" prop="emailAddress" [cellClass]="'email-datatable'"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Role" prop="role" [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Last Login" prop="lastLogin"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Status" prop="status" [width]="50">
            <ng-template let-status="value" ngx-datatable-cell-template>
              <div class="badge badge-pill" [ngClass]="{
                              'badge-light-success': status=='true',
                              'badge-light-warning': status != 'true'
                            }">
                <div *ngIf="status=='true'">
                  Active
                </div>
                <div *ngIf="status!='true'">
                  Inactive
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Created Date" prop="auditInfo.AddTimeStamp" [width]="50">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Modified Date" prop="auditInfo.ChangeTimeStamp" [width]="50">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Actions" [width]="40">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center">
                <a href="javascript:void(0)" data-toggle="tooltip" data-popup="tooltip-custom" placement="left"
                  ngbTooltip="Edit User" class="mr-1" (click)="editSelectedUser(row,'edit')">
                  <i data-feather="edit" class="text-primary cursor-pointer"></i>
                </a>
                <a href="javascript:void(0)" data-toggle="tooltip" data-popup="tooltip-custom" placement="left"
                  ngbTooltip="Resend Verification" class="mr-1" (click)="resendEmail(row)">
                  <i data-feather="send" class="text-primary cursor-pointer"></i>
                </a>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

      </div>
      <div id="employers" class="content">

        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">Show
                <select class="form-control mx-25" [(ngModel)]="page.limit" (ngModelChange)="loadData()">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              <label class="d-flex align-items-center ml-1 ml-md-0">Search:<input name="searchValue" type="search"
                  class="form-control ml-25 search-box" [(ngModel)]="page.searchValue" (ngModelChange)="clear()"
                  (keyup.enter)="filterUpdate($event)" /><button class="reset-icon" type="reset"
                  (click)="reset()"></button></label>
              <button class="btn btn-primary ml-1" rippleEffect (click)="addUser('add')">
                <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                  class="d-none d-sm-inline-block">Create User</span>
              </button>
              <!-- <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('add-new-team-sidebar')">
                          <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                              class="d-none d-sm-inline-block">Add Team</span>
                          </button> -->
            </div>
          </div>
        </div>
        <ngx-datatable [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap" [footerHeight]="30"
          [limit]="page.limit" [externalPaging]="true" [count]="page.count" [offset]="page.offset"
          (page)="datatablePageData($event)" [sorts]="[{prop: 'auditInfo.ChangeTimeStamp', dir: 'desc'}]"
          [columnMode]="ColumnMode.force" [headerHeight]="50" [scrollbarH]="true">
          <ngx-datatable-column [sortable]="false" name="Name" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="cell-line-height">
                  <a href="javascript:;" (click)="editSelectedUser(row, 'edit')" class="font-medium-1 d-block"><span
                      class="font-weight-bold">{{ row.firstName }} {{ row.lastName }}</span></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Email" prop="emailAddress" [cellClass]="'email-datatable'"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Role" prop="role" [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Company" prop="customer.customerName"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Last Login" prop="lastLogin"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Status" prop="status" [width]="50">
            <ng-template let-status="value" ngx-datatable-cell-template>
              <div class="badge badge-pill" [ngClass]="{
                              'badge-light-success': status=='true',
                              'badge-light-warning': status != 'true'
                            }">
                <div *ngIf="status=='true'">
                  Active
                </div>
                <div *ngIf="status!='true'">
                  Inactive
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Created Date" prop="auditInfo.AddTimeStamp" [width]="50">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Modified Date" prop="auditInfo.ChangeTimeStamp" [width]="50">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Actions" [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center">
                <a href="javascript:void(0)" data-toggle="tooltip" data-popup="tooltip-custom" placement="left"
                  ngbTooltip="Edit User" class="mr-1" (click)="editSelectedUser(row, 'edit')">
                  <i data-feather="edit" class="text-primary cursor-pointer"></i>
                </a>
                <a href="javascript:void(0)" data-toggle="tooltip" data-popup="tooltip-custom" placement="left"
                  ngbTooltip="Resend Verification" class="mr-1" (click)="resendEmail(row)">
                  <i data-feather="send" class="text-primary cursor-pointer"></i>
                </a>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

      </div>
      <div id="beneficiaries" class="content">

        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">Show
                <select class="form-control mx-25" [(ngModel)]="page.limit" (ngModelChange)="loadData()">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              <label class="d-flex align-items-center ml-1 ml-md-0">Search:<input name="searchValue" type="search"
                  class="form-control ml-25 search-box" [(ngModel)]="page.searchValue" (ngModelChange)="clear()"
                  (keyup.enter)="filterUpdate($event)" /><button class="reset-icon" type="reset"
                  (click)="reset()"></button></label>
              <button class="btn btn-primary ml-1" rippleEffect (click)="addUser('add')">
                <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                  class="d-none d-sm-inline-block">Create User</span>
              </button>

            </div>
          </div>
        </div>
        <ngx-datatable [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap" [footerHeight]="30"
          [limit]="page.limit" [externalPaging]="true" [count]="page.count" [offset]="page.offset"
          (page)="datatablePageData($event)" [sorts]="[{prop: 'auditInfo.ChangeTimeStamp', dir: 'desc'}]"
          [columnMode]="ColumnMode.force" [headerHeight]="50" [scrollbarH]="true">
          <ngx-datatable-column [sortable]="false" name="Name" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div class="cell-line-height">
                  <a href="javascript:;" (click)="editSelectedUser(row, 'edit')" class="font-medium-1 d-block"><span
                      class="font-weight-bold">{{ row.firstName }} {{ row.lastName }}</span></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Email" prop="emailAddress" [cellClass]="'email-datatable'"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Role" prop="role" [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Company" prop="customer.customerName"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Last Login" prop="lastLogin"
            [width]="100"></ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Status" prop="status" [width]="50">
            <ng-template let-status="value" ngx-datatable-cell-template>
              <div class="badge badge-pill" [ngClass]="{
                              'badge-light-success': status=='true',
                              'badge-light-warning': status != 'true'
                            }">
                <div *ngIf="status=='true'">
                  Active
                </div>
                <div *ngIf="status!='true'">
                  Inactive
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Created Date" prop="auditInfo.AddTimeStamp" [width]="50">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Modified Date" prop="auditInfo.ChangeTimeStamp" [width]="50">
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value | date}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [sortable]="false" name="Actions" [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center">
                <a href="javascript:void(0)" data-toggle="tooltip" data-popup="tooltip-custom" placement="left"
                  ngbTooltip="Edit User" class="mr-1" (click)="editSelectedUser(row,'edit')">
                  <i data-feather="edit" class="text-primary cursor-pointer"></i>
                </a>
                <a href="javascript:void(0)" data-toggle="tooltip" data-popup="tooltip-custom" placement="left"
                  ngbTooltip="Resend Verification" class="mr-1" (click)="resendEmail(row)">
                  <i data-feather="send" class="text-primary cursor-pointer"></i>
                </a>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

      </div>
      <!-- New Sidebar -->
      <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="role-assoc-sidebar"
        overlayClass="modal-backdrop" (collapsed)="false" hideOnEsc="false">
        <app-role-assoc-sidebar *ngIf="edit == 'edit'" [roles]="roles" [editUser]="editUser"
          (closeSideBar)="closeSideBar($event)"></app-role-assoc-sidebar>
      </core-sidebar>
      <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="add-new-user-sidebar"
        overlayClass="modal-backdrop" (collapsed)="false" hideOnEsc="false">
        <app-add-new-user-sidebar *ngIf="edit == 'add'" [roles]="roles"
          (closeSideBar)="closeSideBar($event)"></app-add-new-user-sidebar>
      </core-sidebar>

    </div>
  </div>
</section>





<swal #resendEmailAlert title="Resend Verification" icon="success" [showConfirmButton]="false" [timer]="1500">
</swal>
<block-ui> </block-ui>